import axios from '@/api/config/interceptor'
import { host, riskHost } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//步骤一
// //获取运单库列表
// export const getBillList = (params) => {
//     return new Promise((resolve, reject) => {
//         axios.post(`${host}/api/BillLibrary/GetBillList`, params).then(res => {
//             if (res.code == 0) {
//                 resolve(res.data)
//             } else {
//                 reject(res)
//             }
//         })
//     })  
// }
//获取运单库列表
export const getBillList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Waybill/GetImportFileList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取错误和审核未通过的列表拿来做风控校验
export const Set30wErrorStatus = ( params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/BillLibrary/Set30wErrorStatus?${_paramsToQueryString(params)}`,).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取运单库的运单列表
// export const gillsInfoDetails = (params) => {
//     return new Promise((resolve, reject) => {
//         axios.get(`${host}/api/BillLibrary/BillsInfoDetails?${_paramsToQueryString(params)}`).then(res => {
//             if (res.code == 0) {
//                 resolve(res.data)
//             } else {
//                 reject(res)
//             }
//         })
//     })
// }
//获取运单库的运单列表
export const gillsInfoDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Waybill/GetImportDetails`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//续传
export const excelResume = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/BillLibrary/ExcelResume`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}


//获取运单详情
export const billsInfoDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/BillLibrary/BillsInfoDetails?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}


//获取错误和审核未通过的列表拿来做风控校验
export const billsInfoDetailsErrorStatus = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/BillLibrary/BillsInfoDetailsErrorStatus?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//风控校验
export const riskVerify = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${riskHost}/api/Risk?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//提交人工审核
export const submitManualReview = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBillManual/AddManual`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//下载运单
export const downtBills = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/BillLibrary/DowntBills?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//删除一条运单库
export const deleteBills = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/BillLibrary/DeleteBills`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//保存在线编辑的运单
export const onlineEditingUpdateBills = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/BillLibrary/OnlineEditingUpdateBills`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//更新运单备注
export const updateImportFileInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/BillLibrary/UpdateImportFileInfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//计算运费
export const sumMoney = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/BillLibrary/SumMoney`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取审核信息 
export const sumAuditInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/WayBillManual/ManualResult?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//删除运单库里面的多条运单
export const deleteOneBills = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/BillLibrary/DeleteOneBills`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传凭证
export const addEvidence = (params) => { 
    console.log('params',params)
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/BillLibrary/AddEvidence`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取凭证
export const getEvidence = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/BillLibrary/GetEvidence`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//删除凭证
export const deleteEvidence = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/BillLibrary/DeleteEvidence`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取凭证分类列表
export const getEvidenceTemplate = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/BillLibrary/GetEvidenceTemplate`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//还原回收站
export const updateRecycleEvidence = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/BillLibrary/UpdateRecycleEvidence`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//下载运单模板
export const downTemplate = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataConfig/DownTemplate?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 错误订单重新生成批次
export const CreateNewOrderByErrors = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBill/CreateNewOrderByErrors`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

