<template>
  <div class="vehicleManagePage">
    <!-- 表格区域 -->
    <div class="facilityBox" v-show="!flag.riskVerifying">
      <el-breadcrumb separator="/" style="margin: 0 0 10px 0">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>人工审核运单</el-breadcrumb-item>
        <el-breadcrumb-item>运单信息</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="position: absolute; right: 20px; top: 125px; z-index: 999">
        <el-tooltip placement="top">
          <div slot="content">
            1.点击“审核通过”，运单进入作业流程<br />2.点击“驳回”，视为错误运单，交由用户重新修改
          </div>
          <div style="margin: 0px 20px; color: #0f5fff">
            <i class="el-icon-question"></i>
            <span>帮助</span>
          </div>
        </el-tooltip>
      </div>
      <div class="topbtns">
        <el-button size="medium" @click="$router.go(-1)" icon="el-icon-arrow-left">返回</el-button>
        <el-button type="primary" size="medium" icon="el-icon-download" @click="exportTable()"
          v-show="pageType != 'autoAudit'">导出表格</el-button>
        <el-button type="success" size="medium" :disabled="manuallist.length == 0 ? true : false"
          @click="refuseClick('succ')" icon="el-icon-check" v-show="pageType == 'waitAudit'">审核通过</el-button>
        <el-button type="danger" size="medium" :disabled="manuallist.length == 0 ? true : false"
          @click="refuseClick('reject')" icon="el-icon-close" v-show="pageType == 'waitAudit'">多条驳回</el-button>
        <el-button type="success" size="medium" @click="refuseClick('audit')" icon="el-icon-s-promotion"
          v-show="pageType == 'waitAudit' && isNeedVerifyPower">全部提交批量审核</el-button>
        <el-button type="danger" size="medium" @click="refuseClick('rejecList')" icon="el-icon-close"
          v-show="pageType == 'waitAudit'">全部批量驳回</el-button>
      </div>
      <el-tabs v-model="searchForm.type" @tab-click="changeType">
        <el-tab-pane v-for="item in sceneList" :key="item.Code" :name="item.Code">
          <div slot="label" v-if="item.hasData && pageType == 'waitAudit'" class="hasData">
            <span>{{ item.Name }}</span><span class="dot"></span>
          </div>
          <span slot="label" v-else>{{ item.Name }}</span>
        </el-tab-pane>
      </el-tabs>
      <el-form class="searchForm" :model="searchForm" ref="searchForm">
        <div style="display: flex; flex-wrap: wrap">
          <div style="display: flex; flex-wrap: wrap">
            <el-form-item label="场景类型：" prop="taskSceneTypeID" label-width="150px">
              <el-select v-model="searchForm.taskSceneTypeID" placeholder="请选择场景类型" style="width: 180px" @change="search">
                <el-option v-for="item in taskList" :key="item.TaskSceneTypeID" :label="item.TaskSceneName"
                  :value="item.TaskSceneTypeID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="司机姓名：" prop="keyword" label-width="150px">
              <el-input style="width: 180px" v-model="searchForm.driverName" placeholder="请输入司机姓名"
                @keyup.enter.native="search()">
              </el-input>
            </el-form-item>
          </div>
          <div style="display: flex; flex-wrap: wrap">
            <el-form-item label="车牌号：" prop="keyword" label-width="120px">
              <el-input style="width: 180px" v-model="searchForm.carNumber" placeholder="请输入车牌号"
                @keyup.enter.native="search()">
              </el-input>
            </el-form-item>
            <el-form-item prop="keyword">
              <div style="display: flex">
                <el-dropdown @command="handleCommandName" trigger="click" placement="bottom">
                  <span class="el-dropdown-link">
                    <div style="
                        width: 108px;
                        text-align: right;
                        padding-right: 12px;
                        color: #606266;
                        cursor: pointer;
                        white-space: nowrap;
                      ">
                      {{ enterpriseName
                      }}<i style="margin-left: 2px" class="el-icon-arrow-down">：</i>
                    </div>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="item" v-for="item in nameList" :key="item.id">{{ item.name }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-input style="width: 180px" v-model="searchForm.companyName" placeholder="请输入企业名称"
                  @keyup.enter.native="search()"></el-input>
              </div>
            </el-form-item>
            <el-form-item label-width="10px">
              <div style="white-space: nowrap">
                <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
                <el-button type="primary" size="medium" icon="el-icon-delete" @click="resetForm()">清空</el-button>
                <div @click="isShowSelect" style="
                    color: #409eff;
                    display: inline-block;
                    margin-left: 30px;
                    cursor: pointer;
                  ">
                  <span>{{ showSelect ? "收起" : "展开" }}</span><i
                    :class="showSelect ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                    style="margin-left: 10px; line-height: 30px"></i>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
        <el-collapse-transition>
          <div style="display: flex; flex-wrap: wrap" v-show="showSelect">
            <el-form-item label="待开票申请编号：" prop="keyword" label-width="150px">
              <el-input style="width: 180px" v-model="searchForm.ImportBatchID" placeholder="请输入开票申请编号"
                @keyup.enter.native="search()">
              </el-input>
            </el-form-item>
            <el-form-item label="收款人手机号：" prop="keyword" label-width="150px">
              <el-input style="width: 180px" v-model="searchForm.receivePhone" placeholder="请输入收款人手机号"
                @keyup.enter.native="search()">
              </el-input>
            </el-form-item>
            <el-form-item label="司机手机号：" prop="keyword" label-width="120px">
              <el-input style="width: 180px" v-model="searchForm.driverPhone" placeholder="请输入司机手机号"
                @keyup.enter.native="search()">
              </el-input>
            </el-form-item>
            <el-form-item label="起始地：" prop="keyword" label-width="120px">
              <el-input style="width: 180px" v-model="searchForm.startAddress" placeholder="请输入起始地"
                @keyup.enter.native="search()">
              </el-input>
            </el-form-item>
            <el-form-item label="到达地：" prop="keyword" label-width="120px">
              <el-input style="width: 180px" v-model="searchForm.arriveAddress" placeholder="请输入到达地"
                @keyup.enter.native="search()">
              </el-input>
            </el-form-item>
            <el-form-item label="审核状态：" prop="keyword" label-width="150px" v-if="pageType == 'alreadyAudit'">
              <el-select @change="changeStatus" v-model="searchForm.WaybillStatus" placeholder="请选择审核状态"
                style="width: 180px">
                <el-option v-for="item in statusList" :key="item.Code" :label="item.Name" :value="item.Code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="运单号：" prop="keyword" label-width="150px">
              <el-input style="width: 180px" v-model="searchForm.WaybillID" placeholder="请输入运单号"
                @keyup.enter.native="search()">
              </el-input>
            </el-form-item>
            <el-form-item label="错误类型：" prop="ErrorDesc" label-width="150px">
              <el-input style="width: 180px" v-model="searchForm.ErrorDesc" placeholder="请输入错误描述"
                @keyup.enter.native="search()">
              </el-input>
            </el-form-item>
            <el-form-item label="客户单号：" prop="keyword" label-width="120px">
              <el-input style="width: 180px" v-model="searchForm.carriageBillID" placeholder="请输入客户单号"
                @keyup.enter.native="search()">
              </el-input>
            </el-form-item>
            <el-form-item label="收款人姓名：" prop="keyword" label-width="120px">
              <el-input style="width: 180px" v-model="searchForm.payeeName" placeholder="请输入收款人姓名"
                @keyup.enter.native="search()">
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="keyword">
              <div style="display: flex">
                <el-dropdown @command="handleCommand" trigger="click" placement="bottom">
                  <span class="el-dropdown-link">
                    <div style="
                        width: 108px;
                        text-align: right;
                        padding-right: 12px;
                        color: #606266;
                        cursor: pointer;
                        white-space: nowrap;
                      ">
                      {{ timeName
                      }}<i style="margin-left: 2px" class="el-icon-arrow-down">：</i>
                    </div>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="item" v-for="item in timeList" :key="item.id">{{ item.name }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-date-picker v-model="searchForm.time" type="daterange" start-placeholder="请选择开始时间"
                  end-placeholder="请选择结束时间" value-format="yyyy-MM-dd" style="width: 280px">
                </el-date-picker>
              </div>
            </el-form-item>
          </div>
        </el-collapse-transition>
      </el-form>

      <ux-grid beautifyTable :data="tableData" ref="plxTable" v-loading="loading" @select="selectChange"
        @select-all="selectChange" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" :key="searchForm.type"
        :border="false" :height="tableHeight" v-if="!flag.riskVerifying" moveDownIcon="el-icon-caret-bottom"
        moveUpIcon="el-icon-caret-top" field-title="选择显示列" :field-sort="true" :dialog-data="dialogData"
        :checkbox-config="{ highlight: true }" @show-field="onShowField" @reset="onReset">
        <ux-table-column type="checkbox" align="center" width="55px" fixed="left" show-overflow>
        </ux-table-column>
        <ux-table-column align="center" type="index" field="index" fixed="left" width="60px" title="序号">
        </ux-table-column>
        <ux-table-column align="center" field="ImportBatchID" title="待开票申请编号" fixed="left" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '待开票申请编号')[0].state">
          <template slot-scope="scope">
            <div @click="openRightWaybill(scope.row)" v-if="scope.row.ImportBatchID">
              <el-link type="primary">{{
                scope.row.ImportBatchID
              }}</el-link>
            </div>
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="WaybillID" title="运单号" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '运单号')[0].state">
        </ux-table-column>
        <ux-table-column align="center" field="CarriageBillID" title="客户单号" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '客户单号')[0].state">
        </ux-table-column>
        <ux-table-column align="center" field="InvoiceType" title="发票类型" width="110" show-overflow
          v-if="dialogData.filter(item => item.name == '发票类型')[0].state">
        </ux-table-column>
        <ux-table-column align="center" field="TaskType" title="业务类型" width="110" show-overflow
          v-if="dialogData.filter(item => item.name == '业务类型')[0].state">
        </ux-table-column>
        <ux-table-column align="center" field="TaskSceneName" title="场景类型" width="150" show-overflow
          v-if="dialogData.filter(item => item.name == '场景类型')[0].state">
        </ux-table-column>
        <ux-table-column align="center" field="GoodsOwnerName" title="货主单位" width="180" show-overflow
          v-if="dialogData.filter(item => item.name == '货主单位')[0].state">
          <template slot-scope="scope">
            <div @click="showSupplyDialog(scope, 2)">
              <el-link type="primary">{{ scope.row.GoodsOwnerName }}</el-link>
            </div>
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="DriverName" title="司机姓名" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '司机姓名')[0].state">
          <!-- <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.DriverUserID,scope.row.DriverIDCard)">
              <el-link type="primary">{{ scope.row.DriverName }}</el-link>
            </div>
          </template> -->
        </ux-table-column>
        <ux-table-column align="center" field="DriverPhone" title="司机手机号码" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '司机手机号码')[0].state">
          <!-- <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.DriverUserID,scope.row.DriverIDCard)">
              <el-link type="primary">{{ scope.row.DriverPhone }}</el-link>
            </div>
          </template> -->
        </ux-table-column>
        <ux-table-column align="center" field="DriverIDCard" title="司机身份证号码" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '司机身份证号码')[0].state">
          <template slot-scope="scope">
            <div @click="
              getPersonInfo(scope.row, scope.row.DriverUserID, scope.row.DriverIDCard)
              ">
              <el-link type="primary">
                {{ scope.row.DriverIDCard }}
              </el-link>
            </div>
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="PayeeName" title="收款人姓名" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '收款人姓名')[0].state">
          <!-- <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.PayeeUserID,scope.row.PayeeIDCard)">
              <el-link type="primary"> {{ scope.row.PayeeName }}</el-link>
            </div>
          </template> -->
        </ux-table-column>
        <ux-table-column align="center" field="PayeePhone" title="收款人手机号码" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '收款人手机号码')[0].state">
          <!-- <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.PayeeUserID,scope.row.PayeeIDCard)">
              <el-link type="primary">{{ scope.row.PayeePhone }}</el-link>
            </div>
          </template> -->
        </ux-table-column>
        <ux-table-column align="center" field="PayeeIDCard" title="收款人身份证号码" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '收款人身份证号码')[0].state">
          <template slot-scope="scope">
            <div @click="
              getPersonInfo(scope.row, scope.row.PayeeUserID, scope.row.PayeeIDCard)
              ">
              <el-link type="primary">{{ scope.row.PayeeIDCard }}</el-link>
            </div>
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="LeaderName" title="车队长姓名" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '车队长姓名')[0].state">
          <!-- <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.LeaderUserID,scope.row.LeaderIDCard)">
              <el-link type="primary">{{ scope.row.LeaderName }}</el-link>
            </div>
          </template> -->
        </ux-table-column>
        <ux-table-column align="center" field="LeaderPhone" title="车队长手机号码" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '车队长手机号码')[0].state">
          <!-- <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.LeaderUserID,scope.row.LeaderIDCard)">
              <el-link type="primary">{{ scope.row.LeaderPhone }}</el-link>
            </div>
          </template> -->
        </ux-table-column>
        <ux-table-column align="center" field="LeaderIDCard" title="车队长身份证号码" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '车队长身份证号码')[0].state">
          <template slot-scope="scope">
            <div @click="
              getPersonInfo(scope.row, scope.row.LeaderUserID, scope.row.LeaderIDCard)
              ">
              <el-link type="primary">{{ scope.row.LeaderIDCard }}</el-link>
            </div>
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="CarNumber" title="车牌号" width="100" show-overflow
          v-if="dialogData.filter(item => item.name == '车牌号')[0].state">
          <template slot-scope="scope">
            <div @click="showVehicleInfo(scope.row)">
              <el-link type="primary"> {{ scope.row.CarNumber }}</el-link>
            </div>
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="LoadNetWeight" title="装货净重(吨)" width="100" show-overflow
          v-if="dialogData.filter(item => item.name == '装货净重(吨)')[0].state">
        </ux-table-column>
        <ux-table-column align="center" field="GoodsName" title="货品名称" width="100" show-overflow
          v-if="dialogData.filter(item => item.name == '货品名称')[0].state">
        </ux-table-column>
        <ux-table-column align="center" field="GoodsUnit" title="货品单位" width="100" show-overflow
          v-if="dialogData.filter(item => item.name == '货品单位')[0].state">
        </ux-table-column>
        <ux-table-column align="center" field="LoadingDatetime" title="装货时间" width="180" show-overflow
          v-if="dialogData.filter(item => item.name == '装货时间')[0].state">
        </ux-table-column>
        <ux-table-column align="center" field="SignDatetime" title="签收时间" width="180" show-overflow
          v-if="dialogData.filter(item => item.name == '签收时间')[0].state">
        </ux-table-column>
        <ux-table-column align="center" field="OriginAddress" title="起始地" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '起始地')[0].state">
          <template slot-scope="scope">
            <div @click="openMap(scope.row)">
              <el-link type="primary"> {{ scope.row.OriginAddress }}</el-link>
            </div>
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="ArriveAddress" title="到达地" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '到达地')[0].state">
          <template slot-scope="scope">
            <div @click="openMap(scope.row)">
              <el-link type="primary"> {{ scope.row.ArriveAddress }}</el-link>
            </div>
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="DriverAllotCount" title="司机装货数量" width="100" show-overflow
          v-if="dialogData.filter(item => item.name == '司机装货数量')[0].state">
        </ux-table-column>
        <ux-table-column align="center" field="DriverCarryPrice" title="司机运输单价（人民币）" width="170" show-overflow
          v-if="dialogData.filter(item => item.name == '司机运输单价（人民币）')[0].state">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.DriverCarryPrice | formatMoney }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="CarriageTotalPrice" title="运费" width="100" show-overflow
          v-if="dialogData.filter(item => item.name == '运费')[0].state">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.CarriageTotalPrice | formatMoney }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="ExpensesOfTaxation" title="服务费/税费" width="100" show-overflow
          v-if="dialogData.filter(item => item.name == '服务费/税费')[0].state">
          <template slot-scope="scope">
            <div style="color: #ff8b17" v-if="searchForm.type == '01'">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
            <div style="color: #ff8b17" v-if="searchForm.type == '06'">
              {{ scope.row.TecServiceFee | formatMoney }}
            </div>
            <div style="color: #ff8b17" v-if="$entrustTypeIds.includes(searchForm.type)">
              {{ scope.row.OriginalExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="NeedPayMoney" title="运单总金额" width="100" show-overflow
          v-if="dialogData.filter(item => item.name == '运单总金额')[0].state">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.NeedPayMoney | formatMoney }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="EntrustedCollectionAmount" title="委托代开运费" width="100" show-overflow
          v-if="$entrustTypeIds.includes(searchForm.type) && dialogData.filter(item => item.name == '委托代开运费')[0].state">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.EntrustedCollectionAmount | formatMoney }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="UnloadNetWeight" title="卸货净重(吨)" width="100" show-overflow
          v-if="dialogData.filter(item => item.name == '卸货净重(吨)')[0].state">
        </ux-table-column>
        <ux-table-column align="center" field="OriginalWayBillID" title="溯源运单号" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '溯源运单号')[0].state">
          <template slot-scope="scope">
            <div @click="openDetailDialog(scope.row)">
              <el-link type="primary"> {{ scope.row.OriginalWayBillID }}</el-link>
            </div>
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="WaybillStatusName" title="审核状态" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '审核状态')[0].state">
          <template slot-scope="scope">
            {{ scope.row.WaybillStatusName }}
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="ProviderName" title="审核单位" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '审核单位')[0].state">
        </ux-table-column>
        <ux-table-column align="center" field="Remark1" title="处理意见" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '处理意见')[0].state">
          <template slot-scope="scope">
            {{ scope.row.Remark1 }}
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="Remark" title="处理备注" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '处理备注')[0].state">
          <template slot-scope="scope">
            {{ scope.row.Remark }}
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="Remark" title="运单库备注" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '运单库备注')[0].state">
          <template slot-scope="scope">
            {{ scope.row.FileRemark }}
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="PayeeRemark" title="收款人备注" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '收款人备注')[0].state">
          <template slot-scope="scope">
            {{ scope.row.PayeeRemark }}
          </template>
        </ux-table-column>
        <ux-table-column align="center" field="ErrorDesc" title="风控失败原因" fixed="right" width="200" show-overflow
          v-if="dialogData.filter(item => item.name == '风控失败原因')[0].state">
          <template slot-scope="scope">
            {{ scope.row.ErrorDesc }}
          </template>
        </ux-table-column>
        <ux-table-column align="center" fixed="right" title="凭证管理(线下付款)" width="150"
          v-if="dialogData.filter(item => item.name == '凭证管理(线下付款)')[0].state">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="openVoucherDialog(scope.row,true)" icon="el-icon-picture">凭证管理
            </el-button>
          </template>
        </ux-table-column>
        <ux-table-column align="center" title="操作" width="210" fixed="right" v-if="pageType == 'waitAudit'">
          <template slot="header" slot-scope="scope">
            <div>操作<span style="margin-left: 10px;color:red" @click="openDialog">筛选列</span></div>
          </template>
          <template slot-scope="scope">
            <el-button type="success" size="small" @click="refuseClick('succ', scope.row)" icon="el-icon-check">通过
            </el-button>
            <el-button type="danger" size="small" @click="refuseClick('reject', scope.row)" icon="el-icon-close">驳回
            </el-button>
          </template>
        </ux-table-column>
      </ux-grid>

      <div style="display: flex; align-items: center">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-size="pagination.pagesize"
          :page-sizes="[10, 20, 30, 40, 50, 100, 200, 500, 1000]" @size-change="paginationSizeChange"
          layout="total,sizes, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>

        <!--数量金额总计-->
        <div style="display: flex; margin: 5px 0 0 30px">
          <div style="color: rgb(255, 139, 23); white-space: nowrap">
            运费总计：{{ totalData.CarriageTotalPrice }}
          </div>
          <div style="color: rgb(255, 139, 23); margin: 0 30px; white-space: nowrap">
            委托代开运费总计：{{ totalData.EntrustedCollectionAmount }}
          </div>
        </div>
      </div>
    </div>
    <!-- 审核请求中 -->
    <div class="riskVerify" v-show="flag.riskVerifying">
      <el-progress :width="400" type="circle" :stroke-width="20" :percentage="flag.riskProgress"></el-progress>
      <p class="desc">数据请求中，请稍后...</p>
    </div>
    <!-- 审核结果dialog -->
    <div v-if="flag.reasultDialog">
      <el-dialog title="审核结果" :visible.sync="flag.reasultDialog" width="40%" center>
        <div style="text-align: center; font-size: 16px">
          成功：{{ succNum }}条，失败：{{ errNum }}条
        </div>
        <el-table :data="errorReasultData" style="width: 100%; margin-top: 10px" max-height="350"
          :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-if="errorReasultData.length">
          <el-table-column align="center" type="index" prop="UserID" label="序号" width="50"></el-table-column>
          <el-table-column prop="waybillId" label="运单号" width="230"> </el-table-column>
          <el-table-column prop="msg" label="错误原因" show-overflow-tooltip>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>

    <!-- 驳回弹窗 -->
    <el-dialog title="处理意见" :visible.sync="rejectModel" width="30%" center>
      <span>{{ modelText }} {{ EnterpriseFullName }} 的提交申请：</span>
      <div style="margin: 20px 0 0 0">
        <el-input type="textarea" :rows="6" maxlength="400" show-word-limit placeholder="请输入处理意见，限制400字。"
          v-model="rejectValue"></el-input>
        <div style="margin-top: 16px; font-weight: bold">快捷回复:</div>
        <el-checkbox-group v-model="checkRemarkList">
          <el-checkbox :label="item.Remark" v-for="item in remarkList" :key="item.Code" style="padding: 5px 0">
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectModel = false">取 消</el-button>
        <el-button type="primary" @click="auditClick('succ')" v-if="modelText == '通过'">确 定</el-button>
        <el-button type="primary" @click="submitAudit" v-if="modelText == '全部提交批量审核'">确 定</el-button>
        <el-button type="primary" @click="rejectAudit" v-if="modelText == '全部批量驳回'">确 定</el-button>
        <el-button type="primary" :disabled="!rejectValue && !checkRemarkList.length" v-if="modelText == '驳回'"
          @click="auditClick('reject')">确 定
        </el-button>
      </span>
    </el-dialog>
    <!--服务商/货主信息弹框-->
    <el-dialog top="15vh" class="dialog" :visible.sync="supplyDialog" width="1000px" :close-on-click-modal="false">
      <span slot="title">{{ type == 1 ? "服务商信息" : "货主信息" }}</span>
      <div style="height: 70vh; overflow: auto">
        <SupplyInfo v-if="type == 1" :supplyId="supplyId"></SupplyInfo>
        <BossInfo v-if="type == 2" :supplyId="supplyId" />
      </div>
    </el-dialog>
    <!--人员信息弹框-->
    <el-dialog top="15vh" class="dialog" :visible.sync="personDialog" width="1300px" :close-on-click-modal="false">
      <span slot="title">人员信息</span>
      <DriverInfo :driverInfo="driverInfo" />
    </el-dialog>
    <!--显示车辆信息弹框-->
    <el-dialog top="15vh" class="dialog" :visible.sync="vehicleDialog" width="1300px" :close-on-click-modal="false">
      <span slot="title">车辆信息</span>
      <VehicleInfo :vehicleInfo="vehicleInfo" />
    </el-dialog>
    <!-- 地图组件dialog -->
    <el-dialog width="800px" :visible.sync="flag.showMap" append-to-body>
      <!-- 地图组件 -->
      <TXmap style="height: 600px" v-if="flag.showMap" ref="map" :list="mapList"></TXmap>
    </el-dialog>
    <!-- 上传凭证dialog -->
    <el-dialog class="voucherDialogStyle" width="1200px" title="凭证" center :visible.sync="flag.voucherDialog"
      append-to-body v-if="flag.voucherDialog" :close-on-click-modal="false">
      <EvidenceInfo :businessID="currentItem.WaybillID || currentItem.WaybillNumber" :isShowDetailInfo="isShowInfo" :isShowFiveInfo="false"
        :currentItem="currentItem" :detailInfo="detailInfo">
      </EvidenceInfo>

      <!-- <EvidenceInfo  :isShowFiveInfo="true" :businessID="currentItem.WaybillID"> </EvidenceInfo> -->
    </el-dialog>
    <!-- 溯源运单号查询详情 -->
    <el-dialog class="voucherDialogStyle" title="运单信息" center :visible.sync="flag.detailDialog" width="1200px"
      v-if="flag.detailDialog" :close-on-click-modal="false">
      <EvidenceInfo :businessID="currentItem.WaybillID" :isShowDetailInfo="true" :isShowFiveInfo="true"
        :currentItem="currentItem" :detailInfo="detailInfo" v-if="Object.keys(currentItem).length > 0">
      </EvidenceInfo>
    </el-dialog>
    <el-dialog width="1300px" :visible.sync="flag.rightWaybillDialog" append-to-body
      @close="$refs['searchRightForm'].resetFields()">
      <div style="display: flex; align-items: center">
        <el-form ref="searchRightForm" inline :model="searchRightForm" size="mini">
          <el-form-item label="运单号" prop="WaybillID">
            <el-input v-model="searchRightForm.WaybillID" placeholder="请输入运单号"></el-input>
          </el-form-item>
          <el-form-item label="客户单号" prop="CarriageBillID">
            <el-input v-model="searchRightForm.CarriageBillID" placeholder="请输入客户单号"></el-input>
          </el-form-item>
          <el-button type="primary" size="mini" icon="el-icon-zoom-in" @click="openRightWaybill(currentItem)">搜索
          </el-button>
          <el-button type="primary" size="mini" icon="el-icon-delete" @click="$refs['searchRightForm'].resetFields()">
            清除
          </el-button>
        </el-form>
      </div>
      <!-- 表格信息 -->
      <el-table style="width: 100%" height="400" :data="currentRightWaybillList" v-loading="pagination3.loading"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
        <el-table-column align="center" prop="WaybillNumber" label="运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskType" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Shipper_name" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Phone" label="司机手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="200">
        </el-table-column>
        <el-table-column align="center" prop="PayName" label="收款人姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Recy_payment_idcard" label="收款人手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>

        </el-table-column>
        <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>

        </el-table-column>
        <el-table-column align="center" prop="LicensePlate" label="车牌" width="200" show-overflow-tooltip>

        </el-table-column>
        <el-table-column align="center" prop="Net_weight" label="装货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Product_name" label="货品名称" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Product_unit" label="货品单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Loading_time" label="装货时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Submission_time" label="签收时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Start_addrees" label="起始地" width="200">
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.Start_addrees }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Arrivals" label="到达地" width="200">
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.Arrivals }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Number_allocated_drivers" label="司机装货数量" width="200"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Driver_transportation_price" label="司机运输单价（人民币）" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.Driver_transportation_price | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="amount_count" label="运费" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.amount_count | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Taxes" label="服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.Taxes | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TotalAmount" label="运单总金额" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Entrusted_Collection_Amount" label="委托代开运费" width="200"
          v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.Entrusted_Collection_Amount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="EntrustedTaxes" label="委托代开服务费/税费" width="200"
          v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.EntrustedTaxes | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="EntrustedTotalAmount" label="委托代开运单总金额" width="200"
          v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.EntrustedTotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Unloading_net_weight" label="卸货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="RiskPath" label="风控路径" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Rrmark1" label="处理/驳回原因" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="" label="支付方式" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column fixed="right" align="center" prop="" label="操作" width="130">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="openVoucherDialog(scope.row,false)">凭证</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background style="margin-top: 16px" @current-change="pagination3Change"
        :current-page.sync="pagination3.page" :page-size="pagination3.pagesize" :page-sizes="[10, 20, 30, 40, 50, 100]"
        @size-change="pagination3SizeChange" layout="total, sizes,prev, pager, next, jumper" :total="pagination3.total">
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetReviewWayDetails,
  UpdateReviewWay,
  SubmitAutoManual,
  RejecttAutoManual,
  ManualExist
} from "@/api/manualAudit/index";
import {
  getDataDict,
  getDriverDetails,
  getCarInfo,
  getMapPath,
} from "@/api/common/common";
import {
  gillsInfoDetails
} from "@/api/waybill/waybillManage/waybillRepository";
import { queryWayBillDetail, queryWaybillOnlyOne } from "@/api/waybill/otherAndAllBill/index";
import { getTaskScene } from "@/api/goodsOwner/goodsOwnerManage/basicInfo";
import SupplyInfo from "@/components/allWayBillCmpt/supplyInfoDialog";
import BossInfo from "@/components/allWayBillCmpt/bossInfoDialog";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import TXmap from "@/components/commonCmpt/TXmap";
import EvidenceInfo from "@/components/businessCmpt/evidenceInfo";
import { ManualDetailListDownload } from "@/api/finance/account/index";
import { GetVerifyPower } from "@/api/auditInfo/certified/index";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialogData: [
        { name: '待开票申请编号', state: true },
        { name: '运单号', state: true },
        { name: '客户单号', state: true },
        { name: '发票类型', state: false },
        { name: '业务类型', state: false },
        { name: '场景类型', state: false },
        { name: '货主单位', state: false },
        { name: '司机姓名', state: true },
        { name: '司机手机号码', state: false },
        { name: '司机身份证号码', state: false },
        { name: '收款人姓名', state: true },
        { name: '收款人手机号码', state: false },
        { name: '收款人身份证号码', state: false },
        { name: '车队长姓名', state: false },
        { name: '车队长手机号码', state: false },
        { name: '车队长身份证号码', state: false },
        { name: '车牌号', state: true },
        { name: '装货净重(吨)', state: false },
        { name: '货品名称', state: false },
        { name: '货品单位', state: false },
        { name: '装货时间', state: true },
        { name: '签收时间', state: true },
        { name: '起始地', state: true },
        { name: '到达地', state: true },
        { name: '司机装货数量', state: false },
        { name: '司机运输单价（人民币）', state: false },
        { name: '运费', state: true },
        { name: '服务费/税费', state: false },
        { name: '运单总金额', state: false },
        { name: '委托代开运费', state: true },
        { name: '卸货净重(吨)', state: false },
        { name: '溯源运单号', state: false },
        { name: '审核状态', state: false },
        { name: '审核单位', state: false },
        { name: '处理意见', state: false },
        { name: '处理备注', state: false },
        { name: '运单库备注', state: false },
        { name: '收款人备注', state: false },
        { name: '风控失败原因', state: true },
        { name: '凭证管理(线下付款)', state: true },
      ],
      // 企业名称
      EnterpriseFullName: "",
      // 是否显示驳回弹窗
      rejectModel: false,
      // 驳回意见
      rejectValue: "",
      remarkList: [], // 快捷回复列表
      checkRemarkList: [], // 选中的快捷回复
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      detailInfo: {}, //五流合一详情信息
      // 搜索表单
      searchForm: {
        companyName: "", // 企业名称
        driverPhone: "", // 司机手机号
        driverName: "", // 司机姓名
        carriageBillID: "",
        payeeName: "",
        receivePhone: "", // 收款人手机号
        carNumber: "", // 车牌
        // wayBillID: '', // 运单号
        time: "", // 时间
        type: "01", // 场景类型 默认为查找承运业务
        startAddress: "", // 起始地
        arriveAddress: "", // 到达地
        supplierName: "", // 服务商名称
        DateType: 1, // 时间类型
        enterpriseId: 1, // 货主名称选择类型id
        ImportBatchID: "", //结算单号
        WaybillStatus: "-1", //审核状态
        WaybillID: "", //运单号
        ErrorDesc: "",
        taskSceneTypeID: "", // 场景类型
      },
      tableData: [], //表格数据
      sceneList: [], //发票类型
      taskList: [], // 场景类型
      statusList: [
        {
          Code: "-1",
          Name: "全部",
        },
        {
          Code: "1",
          Name: "通过",
        },
        {
          Code: "2",
          Name: "驳回",
        },
      ], //审核状态
      // 是否禁用提交按钮
      showSubmit: false,
      loading: false,
      showSelect: false, // 控制是否显示筛选框
      // 时间选择列表
      timeList: [
        {
          id: 1,
          name: "签收时间",
        },
        {
          id: 2,
          name: "装货时间",
        },
      ],
      timeName: "签收时间",
      nameList: [
        {
          id: 1,
          name: "货主名称",
        },
        {
          id: 2,
          name: "服务商名称",
        },
      ],
      // 企业名称
      enterpriseName: "货主名称",
      // 已选中数据
      manuallist: [],
      // 用户id
      UserID: "",
      // 上一页 页数
      pageIndex: 1,
      // 上个页面跳转来源 waitAudit 未审核 alreadyAudit 已审核
      pageType: "",
      // 显示服务商/货主信息弹框
      supplyDialog: false,
      // 服务商/货主ID
      supplyId: "",
      // 服务商/货主
      type: 0,
      // 详情
      currentItem: "",
      // 人员弹窗
      personDialog: false,
      // 人员信息
      driverInfo: "",
      // 车辆弹窗
      vehicleDialog: false,
      // 车辆详情
      vehicleInfo: "",
      // 地图
      flag: {
        showMap: false,
        voucherDialog: false, //凭证dialog
        riskVerifying: false,
        riskProgress: 0,
        reasultDialog: false,
        openDetailLoading: false,
        detailDialog: false,
        rightWaybillDialog: false
      },
      modelText: "",
      // 凭证
      currentItem: {},
      tableHeight: null,
      succNum: 0,
      errNum: 0,
      errorReasultData: [],

      isNeedVerifyPower: false, //是否有审核权限

      totalData: {},
      rightWaybillList: [],
      pagination3: {
        //正确运单
        page: 1,
        pagesize: 10,
        total: 0,
        loading: false,
      },
      searchRightForm: {
        CarriageBillID: "",
        WaybillID: "",
      },
      isShowInfo: false
    };
  },
  computed: {
    ...mapGetters(["getWaybillType"]),
    currentRightWaybillList() {
      return this.rightWaybillList.slice(
        this.pagination3.pagesize * (this.pagination3.page - 1),
        this.pagination3.pagesize * this.pagination3.page
      );
    }
  },
  watch: {
    rejectModel(newVal) {
      if (newVal == false) {
        this.rejectValue = "";
        this.manuallist = [];
        this.$refs.plxTable.clearSelection();
      }
    },
  },
  components: {
    EvidenceInfo,
    SupplyInfo,
    DriverInfo,
    VehicleInfo,
    TXmap,
    BossInfo,
  },
  methods: {
    openRightWaybill(item) {
      this.currentItem = item;
      this.flag.rightWaybillDialog = true;
      this.pagination3.loading = true;
      let params = {
        importBatchId: this.currentItem.ImportBatchID,
        pageIndex: 1,
        pageSize: 100000,
        statusList: [1],
        CarriageBillID: this.searchRightForm.CarriageBillID,
        WaybillID: this.searchRightForm.WaybillID,
      };
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      gillsInfoDetails(params)
        .then((res) => {
          this.rightWaybillList = res.data.DataList;
          this.pagination3.total = res.data.TotalCount;
          this.riskTime = JSON.parse(res.data.Extend).RiskTime;
        })
        .finally(() => {
          this.pagination3.loading = false;
          loading.close();
        });
    },
    //正确分页回调
    pagination3Change(e) {
      this.pagination3.page = e;
    },
    pagination3SizeChange(val) {
      this.pagination3.pagesize = val;
    },
    openDialog() {
      this.$refs.plxTable.plDialogOpens()
    },
    onShowField(val) {
      this.dialogData = val
      sessionStorage.setItem("dialogData", JSON.stringify(this.dialogData))
      this.$forceUpdate()
    },
    onReset(val) {
      this.dialogData = this.dialogData.map(it => {
        it.state = true
        return it
      })
    },
    //打开详情弹窗
    openDetailDialog(e) {
      let params = {
        waybillId: e.OriginalWayBillID,
        taskType: this.searchForm.type
      };
      this.flag.openDetailLoading = true;
      queryWaybillOnlyOne(params).then(res1 => {
        this.currentItem = res1.data || {};
      }).finally(() => {
        queryWayBillDetail(params)
          .then((res) => {
            res.data.Vouchers.forEach((item, index) => {
              item.Name = `付款凭证${index + 1}`;
            });
            this.detailInfo = res.data;
            this.flag.detailDialog = true;
            this.flag.openDetailLoading = false;
          })
          .catch(() => {
            this.flag.openDetailLoading = false;
          });
      })
    },
    // 导出表格
    exportTable() {
      this.loading = true;
      let data = {
        fileType: "人工审核运单",
        fileName: "人工审核运单",
        taskType: this.searchForm.type,
        DriverPhone: this.searchForm.driverPhone,
        driverName: this.searchForm.driverName,
        carriageBillID: this.searchForm.carriageBillID,
        payeeName: this.searchForm.payeeName,
        VehicleCarNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: this.searchForm.taskSceneTypeID,
        StartDatetime: this.searchForm.time[0],
        EndDatetime: this.searchForm.time[1],
        DateType: this.searchForm.DateType,
        roleType: this.searchForm.enterpriseId,
        ImportBatchID: this.searchForm.ImportBatchID,
        ApplicantID: this.UserID,
        manualStatusList:
          this.pageType == "waitAudit"
            ? [0]
            : this.pageType == "autoAudit"
              ? [10]
              : this.searchForm.WaybillStatus == "-1"
                ? [1, 2]
                : [this.searchForm.WaybillStatus],
        WaybillID: this.searchForm.WaybillID,
        ErrorDesc: this.searchForm.ErrorDesc,
      };
      ManualDetailListDownload(data)
        .then((res) => {
          window.location.href = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    rejectAudit() {
      if (this.tableData.length <= 0) {
        this.$message.error("无数据可提交");
        return;
      }

      let remark =
        this.rejectValue + (this.rejectValue ? ";" : "") + this.checkRemarkList.join(";");
      if (!remark || remark.length < 6) {
        this.$message.error("处理意见至少填写5个字符");
        return;
      }
      this.$confirm(`确定全部批量驳回吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: "驳回中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        RejecttAutoManual({
          ownerID: this.UserID,
          taskSceneTypeID: this.searchForm.taskSceneTypeID,
          remark: remark,
        })
          .then((res) => {
            this.$message.success("提交成功");
            this.GetReviewWayDetails();
            // setTimeout(() => {
            //   this.$router.go(-1)
            // }, 1500)
          })
          .finally(() => {
            loading.close();
            this.rejectModel = false;
          });
      });
    },
    //打开上传凭证弹窗
    openVoucherDialog(item,type) {
      this.isShowInfo = type
      this.currentItem = item;
      let params = {
        waybillId: item.WaybillID || item.WaybillNumber,
      };
      this.flag.openDetailLoading = true;
      queryWayBillDetail(params)
        .then((res) => {
          res.data.Vouchers.forEach((item, index) => {
            item.Name = `付款凭证${index + 1}`;
          });
          this.detailInfo = res.data;
          this.flag.voucherDialog = true;
          this.flag.openDetailLoading = false;
        })
        .catch(() => {
          this.flag.voucherDialog = false;
          this.flag.openDetailLoading = false;
        });
    },
    //打开地图
    openMap(item) {
      this.flag.loadMaping = true;
      this.loading = true;
      let params = {
        wayBillId: item.WaybillID || item.WaybillNumber,
        searchWord: item.CarNumber || item.LicensePlate,
        stDate: item.LoadingDatetime || item.Loading_time,
        edDate: item.SignDatetime || item.Submission_time,
        coordType: 2,
      };
      getMapPath({ json: JSON.stringify(params) })
        .then((res) => {
          this.mapList = res.Track;
          this.flag.showMap = true;
        })
        .finally(() => {
          this.flag.loadMaping = false;
          this.loading = false;
        });
    },
    // 获取车辆信息
    showVehicleInfo(item) {
      this.loading = true;
      let params = {
        CarNumber: item.CarNumber,
      };
      let data = {};
      getCarInfo(params)
        .then((res) => {
          data = Object.assign(
            {},
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          this.vehicleInfo = data;
          this.vehicleDialog = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查询人员信息
    getPersonInfo(item, UserID, idcard) {
      this.loading = true;
      if (item) {
        this.currentItem = item;
      }
      let querydata = { UserID: UserID };
      if (UserID == 0) {
        querydata = { driverIdCard: idcard };
      }
      getDriverDetails(querydata)
        .then((res) => {
          data = Object.assign({}, res.data.driver_baseInfo, res.data.driver_confirmInfo);
          this.driverInfo = data;
          this.personDialog = true;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 显示服务商/货主信息弹框
    showSupplyDialog(scope, index) {
      if (scope) {
        this.currentItem = scope.row;
      }
      this.type = index;
      if (index == 1) {
        // 服务商信息
        this.supplyId = this.currentItem.PrincipalCompany;
      } else if (index == 2) {
        // 货主信息
        this.supplyId = this.currentItem.UserID;
      }
      this.supplyDialog = true;
    },
    // 单条驳回
    refuseClick(type, item) {
      this.checkRemarkList = [];
      if (type == "reject") {
        this.modelText = "驳回";
        getDataDict({ type: 70 }).then((res) => {
          this.remarkList = res.patterSetInfo;
        });
      } else if (type == "rejecList") {
        this.modelText = "全部批量驳回";
        getDataDict({ type: 70 }).then((res) => {
          this.remarkList = res.patterSetInfo;
        });
      } else if (type == "succ") {
        this.modelText = "通过";
        getDataDict({ type: 69 }).then((res) => {
          this.remarkList = res.patterSetInfo;
        });
      } else if (type == "audit") {
        this.modelText = "全部提交批量审核";
        getDataDict({ type: 69 }).then((res) => {
          this.remarkList = res.patterSetInfo;
        });
      }

      if (item) {
        this.manuallist = [item];
      }
      this.rejectModel = true;
    },
    // 审核运单
    auditClick(type) {
      let remark =
        this.rejectValue + (this.rejectValue ? ";" : "") + this.checkRemarkList.join(";");
      if (!remark || remark.length < 6) {
        this.$message.error("处理意见至少填写5个字符");
        return;
      }

      let arr = this.manuallist;
      let progress = 0; //已经审核了多少条
      let errorCount = 0; //有多少条失败的
      let data = {
        status: type == "succ" ? 1 : 2,
        waybillId: "",
        remark: remark,
      };
      this.$confirm(`确定进行此操作吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.flag.riskVerifying = true;
        this.flag.riskProgress = 0;
        this.rejectModel = false;
        this.errorReasultData = [];
        //开始审核
        arr.forEach((el) => {
          data.waybillId = el.WaybillID;
          UpdateReviewWay(data)
            .then((res) => {
              if (res.data.IsOk == false) {
                errorCount = errorCount + 1;
                this.errorReasultData.push({
                  msg: res.data.Msg,
                  waybillId: res.data.WaybillId,
                });
              }
            })
            .finally(() => {
              progress = progress + 1;
              this.flag.riskProgress = parseInt((progress / arr.length) * 100);
              if (this.flag.riskProgress == 100) {
                //请求完毕后需要刷新列表获取新数据
                this.GetReviewWayDetails();
                this.manuallist = [];
                //延迟一秒关闭界面
                let timer = setTimeout(() => {
                  this.flag.riskVerifying = false;
                  // 一条错的都没有
                  if (errorCount === 0) {
                    this.succNum = arr.length;
                    this.errNum = 0;
                  } else {
                    this.succNum = arr.length - errorCount;
                    this.errNum = errorCount;
                  }
                  this.$message.success("操作成功!");
                  this.flag.reasultDialog = true;
                  clearTimeout(timer);
                }, 1000);
              }
            });
        });
      });
    },
    // 已选中数据
    selectChange(val) {
      this.manuallist = val;
    },
    // 提交批量审核
    submitAudit() {
      if (this.tableData.length <= 0) {
        this.$message.error("无数据可提交");
        return;
      }

      let remark =
        this.rejectValue + (this.rejectValue ? ";" : "") + this.checkRemarkList.join(";");
      if (!remark || remark.length < 6) {
        this.$message.error("处理意见至少填写5个字符");
        return;
      }

      this.$confirm(`确定全部提交批量审核吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.rejectModel = false;
        SubmitAutoManual({
          ownerID: this.UserID,
          IsPlatForm: true,
          taskSceneTypeID: this.searchForm.taskSceneTypeID,
          remark: remark,
        }).then((res) => {
          this.$message.success("提交成功");
          this.GetReviewWayDetails();
          // setTimeout(() => {
          //   this.$router.go(-1);
          // }, 1500);
        });
      });
    },
    // 隐藏/显示筛选框
    isShowSelect() {
      this.showSelect = !this.showSelect;
    },
    //搜索
    search(formName) {
      //搜索前初始化page
      this.pagination.page = 1;
      //获取全部运单列表
      this.GetReviewWayDetails();
    },
    //清空
    resetForm(formName) {
      this.loading = true;
      this.searchForm = {
        companyName: "", // 企业名称
        driverPhone: "", // 司机手机号
        driverName: "",
        carriageBillID: "",
        payeeName: "",
        receivePhone: "", // 收款人手机号
        carNumber: "", // 车牌
        time: [], // 时间
        type: "01", // 发票类型 默认为查找承运业务
        startAddress: "", // 起始地
        arriveAddress: "", // 到达地
        supplierName: "", // 服务商名称
        DateType: this.searchForm.DateType,
        enterpriseId: 1,
        ImportBatchID: "",
        WaybillStatus: "-1", // 审核状态
        WaybillID: "", // 运单号
        ErrorDesc: "",
        taskSceneTypeID: "", // 场景类型
      };
      this.pagination.page = 1;
      this.getTaskList();
    },
    // 切换场景类型
    changeType(val) {
      // this.searchForm.type = val
      this.tableData = [];
      this.pagination.page = 1;
      this.getTaskList();
    },
    // 切换审核状态
    changeStatus(val) {
      this.searchForm.WaybillStatus = val;
      // this.search()
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.manuallist = [];
      //获取全部运单列表
      this.GetReviewWayDetails();
    },
    paginationSizeChange(e) {
      this.pagination.pagesize = e;
      this.manuallist = [];
      //获取全部运单列表
      this.GetReviewWayDetails();
    },
    //获取所有运单列表
    GetReviewWayDetails() {
      this.totalData = {};
      let params = {
        applicantID: this.UserID,
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        taskType: this.searchForm.type,
        DriverPhone: this.searchForm.driverPhone,
        driverName: this.searchForm.driverName,
        carriageBillID: this.searchForm.carriageBillID,
        payeeName: this.searchForm.payeeName,
        VehicleCarNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: this.searchForm.taskSceneTypeID,
        StartDatetime: this.searchForm.time[0],
        EndDatetime: this.searchForm.time[1],
        dateType: this.searchForm.DateType,
        roleType: this.searchForm.enterpriseId,
        ImportBatchID: this.searchForm.ImportBatchID,
        manualStatusList:
          this.pageType == "waitAudit"
            ? [0]
            : this.pageType == "autoAudit"
              ? [10]
              : this.searchForm.WaybillStatus == "-1"
                ? [1, 2]
                : [this.searchForm.WaybillStatus],
        WaybillID: this.searchForm.WaybillID,
        ErrorDesc: this.searchForm.ErrorDesc,
      };
      this.loading = true;
      GetReviewWayDetails(params)
        .then((res) => {
          this.tableData = res.data.DataList || [];
          this.pagination.total = Number(res.data.TotalCount);
          if (res.data.Extend.length > 0) {
            this.totalData = JSON.parse(res.data.Extend);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //获取发票类型列表
    getSceneList() {
      getDataDict({ type: 27 }).then((res) => {
        this.sceneList = res.patterSetInfo || [];
        for (let i = 0; i < this.sceneList.length; i++) {
          let obj = this.sceneList[i];
          let params = {
            applicantID: this.UserID,
            pageSize: 10,
            pageIndex: 1,
            taskType: obj.Code,
            DriverPhone: this.searchForm.driverPhone,
            driverName: this.searchForm.driverName,
            carriageBillID: this.searchForm.carriageBillID,
            payeeName: this.searchForm.payeeName,
            VehicleCarNumber: this.searchForm.carNumber,
            goodsOwnerName: this.searchForm.companyName,
            originAddress: this.searchForm.startAddress,
            arriveAddress: this.searchForm.arriveAddress,
            payeePhone: this.searchForm.receivePhone,
            taskSceneTypeID: "",
            StartDatetime: this.searchForm.time[0],
            EndDatetime: this.searchForm.time[1],
            dateType: this.searchForm.DateType,
            roleType: this.searchForm.enterpriseId,
            ImportBatchID: this.searchForm.ImportBatchID,
            manualStatusList:
              this.pageType == "waitAudit"
                ? [0]
                : this.pageType == "autoAudit"
                  ? [10]
                  : this.searchForm.WaybillStatus == "-1"
                    ? [1, 2]
                    : [this.searchForm.WaybillStatus],
            WaybillID: this.searchForm.WaybillID,
            ErrorDesc: this.searchForm.ErrorDesc,
          };
          this.loading = true;
          ManualExist(params)
            .then((res) => {
              if (res.data) {
                this.$set(obj, "hasData", true);
              } else {
                this.$set(obj, "hasData", false);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    // 获取场景类型列表
    getTaskList() {
      getTaskScene({ InvoiceType: this.searchForm.type }).then((res) => {
        this.taskList = [];
        this.taskList = res.TaskTypeList || [];
        this.searchForm.taskSceneTypeID = this.taskList.length
          ? this.taskList[0].TaskSceneTypeID
          : "";
        //获取全部运单列表
        this.GetReviewWayDetails();
      });
    },
    // 选择时间下拉菜单
    handleCommand(val) {
      this.timeName = val.name;
      this.searchForm.DateType = val.id;
      // this.search()
    },
    // 货主名称下拉
    handleCommandName(val) {
      this.enterpriseName = val.name;
      this.searchForm.enterpriseId = val.id;
      // this.search()
    },

    //计算table高度(动态设置table高度)
    getTableHeight() {
      let tableH = 400; // 页面其余元素的高度(估值)
      this.$nextTick(() => {
        let formH = this.$refs.searchForm.$el.offsetHeight; // form 查询表单的高度
        this.tableHeight =
          window.innerHeight - tableH - formH <= 150
            ? 150
            : window.innerHeight - tableH - formH;
      });
    },
  },
  created() {
    this.pageIndex = this.$route.query.pageIndex ? this.$route.query.pageIndex : 1;
    this.UserID = this.$route.query.UserID;
    this.EnterpriseFullName = this.$route.query.EnterpriseFullName;
    this.pageType = this.$route.query.type;
    //获取发票类型列表
    this.getSceneList();
    // 获取场景类型列表
    this.getTaskList();
    // 获取table表格高度
    this.getTableHeight();

    GetVerifyPower({ code: "WaybillUsers" }).then((res) => {
      this.isNeedVerifyPower = res.data;
    });
  },
  mounted() {
    let dialogColumns = sessionStorage.getItem("dialogData")
    if (dialogColumns) {
      this.dialogData = JSON.parse(dialogColumns)
    }
    //挂载window.onresize事件(动态设置table高度)
    let _this = this;
    window.onresize = () => {
      if (_this.resizeFlag) {
        clearTimeout(_this.resizeFlag);
      }
      _this.resizeFlag = setTimeout(() => {
        _this.getTableHeight();
        _this.resizeFlag = null;
      }, 100);
    };
  },
  beforeRouteLeave(to, from, next) {
    to.meta.pagetype = this.pageType;
    next();
  },
};
</script>

<style lang="scss"></style>

<style scoped lang="scss">
.vehicleManagePage {
  .hasData {
    display: flex;
    align-items: center;
    position: relative;

    .dot {
      display: block;
      width: 8px;
      height: 8px;
      background-color: red;
      border-radius: 50%;
      margin-top: -14px;
    }
  }

  .riskVerify {
    height: calc(100vh - 150px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;

    .desc {
      font-size: 30px;
      font-weight: 700;
      margin-top: 30px;
    }
  }

  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .showSelect {
    line-height: 40px;
    height: 40px;
    margin-left: 20px;
    color: #606266;
    cursor: pointer;
  }

  .el-form-item {
    margin-bottom: 14px;
  }

  .head {
    display: flex;

    .btn {
      vertical-align: top;
      margin: 15px 0 0 16px;
    }
  }

  .vehicleType {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .uploadArea {
      margin-top: 20px;

      .confirmInfoForm {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .hint {
      display: flex;
      justify-content: center;
    }

    .choose {
      display: flex;
      justify-content: center;
    }
  }

  .btns {
    margin-bottom: 20px;
  }

  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }

  .addForm {
    display: flex;
    flex-wrap: wrap;

    .el-form-item {
      width: 450px;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }

  .pagination {
    margin-top: 10px;
  }
}

.voucherDialogStyle {
  ::v-deep .el-dialog__body {
    height: 600px;
    overflow: auto;
  }
}
</style>
